import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, Validator } from 'redux-form';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';

import { FieldIndicators } from 'src/app/components/forms/FieldIndicators';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  validate?: Validator | Validator[];
  placeholder?: string;
  hideValidMarker?: boolean;
  format?: (value: any) => any;
  parse?: (value: any) => any;
}

@withStyles(require('./TextInput.scss'))
export class TextInput extends React.Component<TextInputProps> {
  render() {
    return (
      <Field
        placeholder={this.props.placeholder}
        component={textInputField}
        name={this.props.name}
        validate={this.props.validate}
        {...this.props}
      />
    );
  }
}

const textInputField = ({
  hideValidMarker,
  input,
  label,
  placeholder,
  disabled,
  meta: { touched, error, warning }
}: any) => (
  <div className="TextInput__container">
    <FormattedMessage id={label ? label : 'placeholder.blank'}>
      {labelTxt => (
        <FormattedMessage id={placeholder ? placeholder : 'placeholder.blank'}>
          {// tslint:disable-next-line:jsx-no-multiline-js
          placeholderTxt => (
            <>
              <label className="TextInput__label">{labelTxt}</label>
              <input
                className={'TextInput ' + (touched && error && 'TextInput--error')}
                placeholder={placeholderTxt}
                type="text"
                disabled={disabled}
                {...input}
              />
            </>
          )}
        </FormattedMessage>
      )}
    </FormattedMessage>

    {touched && error && <FieldIndicators indicator="error" />}
    {touched && error && (
      <div className="TextInput__error">
        <FormattedMessage id={error} />
      </div>
    )}
    {hideValidMarker === undefined ? touched && !error && <FieldIndicators indicator="valid" /> : ''}
  </div>
);
