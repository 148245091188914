import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';

interface FieldIndicatorsProps {
  indicator: string;
}

@withStyles(require('./FieldIndicators.scss'))
export class FieldIndicators extends React.Component<FieldIndicatorsProps> {
  render() {
    switch (this.props.indicator) {
      case 'valid':
        return (
          <div className="FieldIndicator FieldIndicator--positive">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        );
      case 'error':
        return (
          <div className="FieldIndicator FieldIndicator--negative">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        );
      default:
        return undefined;
    }
  }
}
