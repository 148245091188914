import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, InjectedFormProps, reduxForm } from 'redux-form';

import { Button } from 'src/app/components/forms/Button';
import { PasswordInput } from 'src/app/components/forms/PasswordInput';
import { TextInput } from 'src/app/components/forms/TextInput';
import { required } from 'src/app/constants/validation';

export interface LoginFormData {
  username: string;
  password: string;
}

interface InjectedProps {
  injectedHandleSubmit: (formData: LoginFormData) => Promise<void | Error>;
}

type LibProps = InjectedFormProps<LoginFormData, InjectedProps>;

type LoginFormProps = InjectedProps & LibProps;

@withStyles(require('./Login.scss'))
class LoginFormComponent extends React.Component<LoginFormProps> {
  handleSubmit = (formData: LoginFormData) => {
    return this.props.injectedHandleSubmit(formData);
  }

  render() {
    const { error, submitting, handleSubmit } = this.props;
    return (
      <div className="LoginForm LoginForm__container">
      <div className="LoginForm__inner">
        <div className="LoginForm__logo"/>
        <Form className="LoginForm__form" onSubmit={handleSubmit(this.handleSubmit)}>
            <TextInput
              hideValidMarker={true}
              name="username"
              validate={[required]}
              label="label.login.username"
              placeholder="placeholder.login.username"
            />
            <PasswordInput
              hideValidMarker={true}
              name="password"
              validate={[required]}
              label="label.login.password"
              placeholder="placeholder.login.password"
            />
          <div>
            {error && <p className="LoginForm__error">{error}</p>}
            <Button figure="LoginSubmit" color="Positive" disabled={submitting} onClick={handleSubmit}>
              <FormattedMessage id="action.login.submit"/>
            </Button>
          </div>
        </Form>
      </div>
      </div>
    );
  }
}

export const LoginForm = reduxForm<LoginFormData, InjectedProps>({ form: 'loginForm' })(LoginFormComponent);
