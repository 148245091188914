import { FetchResult } from 'apollo-link';
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';

import { withAuth } from 'src/app/components/WithAuth';
import { DASHBOARD } from 'src/app/constants/route';
import { LoginForm, LoginFormData } from 'src/app/containers/Login/LoginForm';
import { initialData } from 'src/platforms/config/graphql';
import { LOGIN_QUERY, LoginQueryResult, LoginQueryVariables } from './Login.queries';

type LoginProps = RouteComponentProps;

@withStyles(require('./Login.scss'))
class LoginContainer extends React.Component<LoginProps> {
  handleSubmit = (loginFn: MutationFn<LoginQueryResult, LoginQueryVariables>) => (formData: LoginFormData) => {
    return loginFn({ variables: { loginInput: formData } })
      .then((result: void | FetchResult) => {
        if (result && result.errors) {
          throw new Error(result.errors[0].message);
        }
        this.props.history.push(DASHBOARD.path);
      })
      .catch(error => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  render() {
    return (
      <section className="login__container container container-fluid">
        <Helmet>
          <title>Jumpstart – Login</title>
        </Helmet>
        <Mutation<LoginQueryResult, LoginQueryVariables>
          mutation={LOGIN_QUERY}
          // tslint:disable-next-line:jsx-no-lambda
          update={cache => cache.writeData(initialData)}
        >
          {// tslint:disable-next-line:jsx-no-multiline-js
          (mutateFn: MutationFn<LoginQueryResult, LoginQueryVariables>) => {
            return <LoginForm injectedHandleSubmit={this.handleSubmit(mutateFn)} />;
          }}
        </Mutation>
      </section>
    );
  }
}

export const Login = (props: LoginProps) =>
  withAuth({ Component: LoginContainer, componentProps: props, isAuthenticated: false });
